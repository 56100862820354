// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-404-mdx": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/404.mdx" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-404-mdx" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-articles-index-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/articles/index.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-articles-index-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-auth-0-callback-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/auth0_callback.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-auth-0-callback-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-blog-index-js": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/blog/index.js" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-blog-index-js" */),
  "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-demo-mdx": () => import("./../../../node_modules/@swizec/gatsby-theme-course-platform/src/pages/demo.mdx" /* webpackChunkName: "component---node-modules-swizec-gatsby-theme-course-platform-src-pages-demo-mdx" */),
  "component---src-pages-bonus-mdx": () => import("./../../../src/pages/bonus.mdx" /* webpackChunkName: "component---src-pages-bonus-mdx" */),
  "component---src-pages-case-studies-mdx": () => import("./../../../src/pages/case-studies.mdx" /* webpackChunkName: "component---src-pages-case-studies-mdx" */),
  "component---src-pages-course-mdx": () => import("./../../../src/pages/course.mdx" /* webpackChunkName: "component---src-pages-course-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-intro-mdx": () => import("./../../../src/pages/intro.mdx" /* webpackChunkName: "component---src-pages-intro-mdx" */),
  "component---src-pages-worksheet-mdx": () => import("./../../../src/pages/worksheet.mdx" /* webpackChunkName: "component---src-pages-worksheet-mdx" */)
}

