import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Box, Link, Container } from "theme-ui";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Container sx={{
      mt: 30,
      pb: 120
    }} mdxType="Container">
      <h2 {...{
        "id": "this-content-is-exclusive-for-students"
      }}>{`This content is exclusive for students`}</h2>
      <p>{`Please login to view this page.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      