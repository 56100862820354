import { Box, Flex, Button, Container } from "theme-ui";
import { graphql } from "gatsby";
import { AboutMeFeatures, Banner, CopyBlock, EmailSignup, Grid, JumpToCourse, LazyImage, LinkImage, NavGrid, Pricing, Testimonial, Title, Video } from "@swizec/gatsby-theme-course-platform";
import * as React from 'react';
export default {
  Box,
  Flex,
  Button,
  Container,
  graphql,
  AboutMeFeatures,
  Banner,
  CopyBlock,
  EmailSignup,
  Grid,
  JumpToCourse,
  LazyImage,
  LinkImage,
  NavGrid,
  Pricing,
  Testimonial,
  Title,
  Video,
  React
};